import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import './index.css';

import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import Cookies from 'js-cookie';
import AccessManagmentPage from 'pages/AccessManagmentPage';
import BespokeSolutionsPage from 'pages/BespokeSolutionsPage';
import Dashboard from 'pages/Dashboard';
import DigitalShelfPage from 'pages/DigitalShelfPage';
import LoginPage from 'pages/LoginPage';
import MediaAnalyticsPage from 'pages/MediaAnalyticsPage';
import MultiChannelPage from 'pages/MultiChannelPage';
import PortalPage from 'pages/PortalPage';
import RetailAnalyticsPage from 'pages/RetailAnalyticsPage';
import VerifyEmailPage from 'pages/VerifyEmailPage';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import LoggedOutRoute from 'routes/LoggedOutRoute';
import PrivateRoute from 'routes/PrivateRoute';
import CustomCloseButton from './components/CustomCloseButton';
import NotFoundPage from './pages/NotFound';
import ComponentsPage from 'pages/ComponentsPage';
import PlatformManagementPage from 'pages/PlatformManagementPage';
import RedirectPage from 'pages/RedirectPage';

const Root = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      const token =
        'U2FsdGVkX19V02AuBnvtzMlYkoP3YGESq29/kxk6tK34BNtIyOw8yh1R1GztFmL/EQmKGnrYxg1mbm7lr6HBPp2nJqU8Ac8Srb2Brykna/y5LFP0+wW2jzcTuJXaxyQJnzt0DMx9HmA4+TSGlQqyyQ==';
      Cookies.set('token', token);
      Cookies.set('uname', 'Moatez Bejaoui');
      Cookies.set('email', 'moatez.bejaoui@frontrowgroup.de');
      Cookies.set('oauth_app', 'AMZ-AD');
      Cookies.set('oauth_account_id', 'amzn1.vg.145391');
      // Cookies.set('oauth_job_id', '6776993bd8baf83711e4f2ca');
      Cookies.set(
        'oauth_state',
        'a98909527527a228d5418cc53d488d865831f87c29b26f6cd3400cd352f6ffa078adaf111a27bd696d8cfacaa02364ae',
      );
    }
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/"
          element={
            <LoggedOutRoute>
              <LoginPage />
            </LoggedOutRoute>
          }
        />
        <Route
          path="/verify-email"
          element={
            <LoggedOutRoute>
              <VerifyEmailPage />
            </LoggedOutRoute>
          }
        />

        <Route
          path="/portal"
          element={
            <PrivateRoute>
              <PortalPage />
            </PrivateRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="retail" element={<RetailAnalyticsPage />} />
          <Route path="media" element={<MediaAnalyticsPage />} />
          <Route path="digital-shelf" element={<DigitalShelfPage />} />
          <Route path="bespoke-solutions" element={<BespokeSolutionsPage />} />
          <Route path="multi-channel" element={<MultiChannelPage />} />
        </Route>
        <Route
          path="access-managment"
          element={
            <PrivateRoute>
              <AccessManagmentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="platform-management"
          element={
            <PrivateRoute>
              <PlatformManagementPage />
            </PrivateRoute>
          }
        />
        {process.env.NODE_ENV === 'development' && (
          <Route
            path="components"
            element={
              <PrivateRoute>
                <ComponentsPage />
              </PrivateRoute>
            }
          />
        )}
        <Route path="/redirect" element={<RedirectPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </>,
    ),
  );
  return (
    <div>
      <RouterProvider router={router} />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastClassName={({ defaultClassName }) =>
          `${defaultClassName} overflow-hidden rounded-lg bg-white shadow-lg`
        }
        bodyClassName={() => 'flex items-center justify-center gap-1 p-2'}
        closeButton={<CustomCloseButton />}
      />
    </div>
  );
};
export default Root;
