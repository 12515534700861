import React from 'react';
import { IStep } from 'types/IStep';

interface TextStepperProps {
  steps: IStep[];
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const TextStepper = ({ steps, setActiveStep, activeStep }: TextStepperProps) => {
  const handleStep = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div className="flex flex-col ">
      <nav>
        <ol className="flex items-center justify-center">
          {steps.map((step, stepIdx) => (
            <li key={step.title}>
              {stepIdx === activeStep ? (
                <div
                  className="flex items-center  py-4 text-sm font-medium font-worksans_medium cursor-pointer"
                  aria-current="step"
                  tabIndex={-1}
                  onKeyUp={() => handleStep(stepIdx)}
                  role="button"
                  onClick={() => handleStep(stepIdx)}
                >
                  <div className="flex">
                    {step.title}
                    {stepIdx !== steps.length - 1 && <div className="mx-2">-</div>}
                  </div>
                </div>
              ) : (
                <div
                  className="group flex items-center cursor-pointer"
                  style={step.disabled ? { pointerEvents: 'none', opacity: 0.4 } : undefined}
                  tabIndex={-1}
                  onKeyUp={() => handleStep(stepIdx)}
                  role="button"
                  onClick={() => handleStep(stepIdx)}
                >
                  <span className="flex items-center  py-4 text-sm font-medium font-worksans_regular">
                    <span className="text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      <div className="flex">
                        {step.title}
                        {stepIdx !== steps.length - 1 && <div className="mx-2">-</div>}
                      </div>
                    </span>
                  </span>
                </div>
              )}
            </li>
          ))}
        </ol>
      </nav>
      <div className="pt-3 lg:pt-3  bg-white overflow-hidden">
        {steps[activeStep]?.children
          ? React.cloneElement(steps[activeStep].children, {
              handleNext: () => handleStep(activeStep + 1),
              selectedStepIndex: activeStep,
              isLastStep: activeStep === steps.length,
            })
          : null}
      </div>
    </div>
  );
};

export default TextStepper;
