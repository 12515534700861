import React from 'react';
import VendorCentralGuide from '../../assets/images/accountOnboarding/Authenticate.svg';
import Button from 'components/Button';

interface AuthenticateAccountProps {
  onSelect?: () => void;
  handleNext?: () => void;
}
const AuthenticateAccount: React.FC<AuthenticateAccountProps> = ({ onSelect }) => (
  <div className="flex flex-col items-center justify-center h-[40vh]">
    <img src={VendorCentralGuide} alt="Vendor Central Guide" />
    <div className="text-center w-[70%] mt-5">
      <div className="font-worksans_medium text-[20px]">Authenticate With Amazon</div>
      <div className="font-worksans_regular text-sm mt-2 text-gray_text">
        Please authenticate so we can fetch your available Amazon Vendor Central account
      </div>
    </div>
    <div className="flex justify-center absolute bottom-6 right-0 left-0 ">
      <Button
        containerStyles="w-72"
        type="submit"
        btnType="primary"
        btnSize="md"
        main="Authenticate"
        onClick={() => {
          onSelect();
        }}
      />
    </div>
  </div>
);

export default AuthenticateAccount;
